import React, {useEffect} from 'react';
import {graphql} from 'gatsby';
import {Link, Trans, useTranslation, useI18next} from 'gatsby-plugin-react-i18next';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import InnerPage from "../../components/innerPage";
import forms from "../../../content/assets/images/forms.jpg"
import auditReports from "../../../content/assets/images/audit-reports.jpg"
import annualReports from "../../../content/assets/images/annual-reports.jpg"

/**
 * publications and forms page
 * @returns {JSX.Element}
 * @private
 */
const PublicationsAndForms = () => {
    const {t} = useTranslation();
    const {languages, originalPath} = useI18next();

    const breadcrumbs = [
        {
            title: t('publicationsAndForms')
        }
    ]

    /**
     * Object Array for generating the "Reports section"
     * @type {[{icon: string, text: string, url: string}]}
     */
    const reportSectionList = [
        {
            icon: annualReports,
            text: t('annualReports'),
            url: "/publications-and-forms/annual-reports"
        },
        {
            icon: auditReports,
            text: t(`auditReports`),
            url: "/publications-and-forms/audit-reports"
        },
        {
            icon: forms,
            text: t('forms'),
            url: "/publications-and-forms/forms"
        },
    ]

    useEffect(() => {

    }, [])

    return (
        <Layout>
            <SEO title={t('publicationsAndForms')}/>
            <InnerPage
                breadcrumbs={breadcrumbs}
                pageTitle={t('publicationsAndForms')}
            >
                <section id="reports" className={"tabs py-0"}>
                    <div className="container" data-aos="fade-up" >

                        <div className="row">
                            {reportSectionList.map((e, index) => {
                                return (
                                    <div className="content col-12 col-md-4 d-flex align-items-stretch my-3 my-md-0"
                                         key={index}
                                    >
                                        <div className="content text-center mx-auto px-0 py-4 hover-highlight">
                                            <Link to={e.url}>
                                                <img src={e.icon} alt={e.text} className={"img-thumbnail"} />
                                                <h3 className={"mt-4"}>{e.text}</h3>
                                            </Link>

                                        </div>
                                    </div>
                                )
                            })}

                        </div>
                    </div>
                </section>
            </InnerPage>
        </Layout>
    );
};

export default PublicationsAndForms;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;